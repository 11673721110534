@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-primary {
    background-color: #091933;
}

.primary {
    color: #091933;
}

.b-accent {
    border-color: #709be0;
}

.text-accent {
    border-color: #709be0;
    color: #709be0;
}

.text-secondary {
    color: #112f6b;
}

.slick-dots {
    margin-left: 0;
    margin-top: 0px;
    bottom: 50px;
    color: #709be0;
}

.slick-dots li button:before {
    color: #fff;
}
.slick-dots li button:active {
    color: #fff;
}

.slick-active li{
    color: #709be0 !important;
}

tr {
    border-top: 1px solid #c6cbd1;
    background: #fff;
}

th,
td {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
}

table tr:nth-child(2n) {
    background: #f6f8fa;
}
